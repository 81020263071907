import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { Chart as ChartJS, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import Loader from 'components/Loader';
import Layout from 'layout/Dashboard';

import 'react-datepicker/dist/react-datepicker.css';

ChartJS.register(...(registerables || []));
ChartJS.register(annotationPlugin);

const GlobalStyle: any = createGlobalStyle`
  .nowrap {
    white-space: nowrap;
  }
  .pointer {
    cursor: pointer;
  }
  .text-center {
    text-align: center;
  }
  .noselect {
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */
  }
`;

const Home = React.lazy(() => import('./pages/Home'));
const Profile = React.lazy(() => import('./pages/Profile'));
const AuthPage = React.lazy(() => import('./pages/Auth'));
const JournalPage = React.lazy(() => import('./pages/Journal'));
const ArticlePage = React.lazy(() => import('./pages/Article'));
const DraftPage = React.lazy(() => import('./pages/Draft'));
const LeaderPage = React.lazy(() => import('./pages/Leader'));
const DashboardPage = React.lazy(() => import('./pages/Dashboard'));
const CharacterBuilding = React.lazy(() => import('./pages/CharacterBuilding'));
const Analytics = React.lazy(() => import('./pages/Analytic'));

function Contents() {
  return (
    <Layout>
      <Routes>
        <Route path="/journal/*" element={<JournalPage />} />
        <Route path="/article/*" element={<ArticlePage />} />
        <Route path="/drafts/*" element={<DraftPage />} />
        <Route path="/leader/*" element={<LeaderPage />} />
        <Route path="/character-building/*" element={<CharacterBuilding />} />
        <Route path="/analytics/*" element={<Analytics />} />
        <Route path="/dashboard/*" element={<DashboardPage />} />
        <Route path="/profile/*" element={<Profile />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  );
}
function App() {
  return (
    <Router>
      <GlobalStyle />
      <Suspense fallback={<p>Loading...</p>}>
        <Routes>
          <Route path="/auth/*" element={<AuthPage />} />
          <Route path="/*" element={<Contents />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
