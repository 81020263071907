import { MenuItemType } from '@paljs/ui/types';

const items: (MenuItemType & { role: string[]; permission: string[] })[] = [
  {
    title: 'Admin Overview',
    icon: { name: 'activity-outline' },
    link: { to: '/dashboard/admin-overview' },
    role: ['system_admin'],
    permission: [],
  },
  {
    title: 'Overview',
    icon: { name: 'activity-outline' },
    link: { to: '/dashboard/overview' },
    role: ['member'],
    permission: [],
  },
  {
    title: 'Users',
    icon: { name: 'people-outline' },
    link: { to: '/dashboard/users' },
    role: ['system_admin'],
    permission: ['user.manage'],
  },
  {
    title: 'Content Writers',
    icon: { name: 'people-outline' },
    link: { to: '/dashboard/writers' },
    role: ['content_admin'],
    permission: ['article.publish'],
  },
  {
    title: 'Articles',
    icon: { name: 'book-open-outline' },
    link: { to: '/dashboard/articles' },
    role: ['content_admin'],
    permission: ['article.manage'],
  },
  {
    title: 'Drafts',
    icon: { name: 'book-open-outline' },
    link: { to: '/dashboard/drafts' },
    role: ['content_writer'],
    permission: [],
  },
  {
    title: 'Article Requests',
    icon: { name: 'book-open-outline' },
    link: { to: '/dashboard/article-requests' },
    role: ['content_admin'],
    permission: ['article.publish'],
  },
  {
    title: 'Followers',
    icon: { name: 'people-outline' },
    link: { to: '/dashboard/followers' },
    role: ['leader'],
    permission: ['client.manage.mine'],
  },
];

const getMenuItems = (user) => {
  if (!user) return [];

  const menuItems: MenuItemType[] = items.filter(
    (item) => item.role.some((v) => !!user[`is_${v}`]) || item.permission.some((v) => user.permissions[v])
  );

  return menuItems;
};

export default getMenuItems;
