import React, { useState, useRef, useEffect, Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled, { DefaultTheme, ThemeProvider } from 'styled-components';
import themes from './themes';
import { Layout, LayoutContent, LayoutFooter, LayoutContainer, LayoutColumns, LayoutColumn } from '@paljs/ui/Layout';
import icons from '@paljs/icons';
import { SidebarBody, SidebarRefObject, Sidebar } from '@paljs/ui/Sidebar';
import noop from 'lodash/noop';
import Header from './Header';
import SimpleLayout from './SimpleLayout';
import { EvaIcon } from '@paljs/ui/Icon';
import { Button } from '@paljs/ui/Button';
import { Menu, MenuRefObject } from '@paljs/ui/Menu';
import getMenuItems from './menuItem';
import ClickLink from 'components/ClickLink';
import CookieBanner from 'components/CookieBanner';

import { getDefaultTheme } from './helpers';
import { session } from 'services/auth';

export interface UserSession {
  id: string;
  name: string;
  email: string;
  image_url: string;
  is_system_admin: boolean;
  risk_need_score: number;
  risk_capacity_score: number;
  risk_tolerance_score: number;
  permissions: { [key: string]: boolean };
}

export interface UserContextInterface {
  user: UserSession | null;
  getSession: () => void;
}

export const UserContext = React.createContext<UserContextInterface | null>(null);

const Bold = styled.div`
  font-weight: 800;
`;

interface Props {
  children?: React.ReactNode;
}

const LayoutPage: React.FC = ({ children }: Props) => {
  const [theme, setTheme] = useState<DefaultTheme['name']>('default');
  const [dir, setDir] = useState<'ltr' | 'rtl'>('ltr');
  const sidebarRef = useRef<SidebarRefObject>(null);
  const location = useLocation();
  const [menuState, setMenuState] = useState(false);
  const menuRef = useRef<MenuRefObject>(null);
  const [seeHeader, setSeeHeader] = useState(true);

  const [user, setUser] = useState(null);

  const getSession = async () => {
    const user = await session();
    setUser(user);
  };

  useEffect(() => {
    getSession();
  }, []);

  const getState = (state?: 'hidden' | 'visible' | 'compacted' | 'expanded') => {
    setSeeHeader(state !== 'compacted');
  };

  const changeTheme = (newTheme: DefaultTheme['name']) => {
    setTheme(newTheme);
    typeof localStorage !== 'undefined' && localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    const localTheme = getDefaultTheme();
    if (localTheme !== theme && theme === 'default') {
      setTheme(localTheme);
    }
  }, []);

  const changeDir = () => {
    const newDir = dir === 'ltr' ? 'rtl' : 'ltr';
    setDir(newDir);
  };

  const dashboardLayout = location.pathname.startsWith('/dashboard');

  const year = new Date().getFullYear();
  const TProvider = ThemeProvider as any;

  return (
    <UserContext.Provider value={{ user, getSession }}>
      {/* fix theme to `default` */}
      <TProvider theme={themes('default', dir)}>
        {/* <CookieBanner /> */}
        <>
          <SimpleLayout />
          <Layout evaIcons={icons} dir={dir} className={dashboardLayout ? 'dashboard-layout' : ''}>
            <Header
              dir={dir}
              changeDir={changeDir}
              theme={{ set: changeTheme, value: theme }}
              toggleSidebar={() => sidebarRef.current?.toggle()}
            />
            <LayoutContainer>
              {dashboardLayout && (
                <Sidebar
                  getState={getState}
                  ref={sidebarRef}
                  property="start"
                  containerFixed
                  responsive
                  className="menu-sidebar"
                >
                  <SidebarBody>
                    <Menu
                      className="sidebar-menu"
                      Link={Link}
                      ref={menuRef}
                      items={getMenuItems(user)}
                      currentPath={location.pathname}
                      toggleSidebar={() => sidebarRef.current?.hide()}
                    />
                  </SidebarBody>
                </Sidebar>
              )}

              <LayoutContent>
                <LayoutColumns>
                  <LayoutColumn className="main-content" style={{ padding: 0 }}>
                    {children}
                  </LayoutColumn>
                </LayoutColumns>
                <LayoutFooter>
                  <Bold>© {year} WealthBuff Inc. | All rights reserved</Bold>
                </LayoutFooter>
              </LayoutContent>
            </LayoutContainer>
          </Layout>
        </>
      </TProvider>
    </UserContext.Provider>
  );
};

export default LayoutPage;
