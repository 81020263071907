import { instance } from './axios';

export async function session() {
  try {
    const data = await instance.get('/api/auth/session').then((res) => res.data);

    return data;
  } catch {
    return null;
  }
}

export async function register({ email, password, repassword, skip_verification = false }: any) {
  try {
    const data = await instance
      .post('/api/auth/register', { email, password, repassword, skip_verification })
      .then((res) => res.data);

    return data;
  } catch {
    return null;
  }
}

export async function completeSignup({ token }: any) {
  try {
    const data = await instance.get(`/api/auth/complete-signup/${token}`).then((res) => res.data);

    return data;
  } catch {
    return null;
  }
}

export async function login({ username, password }: any) {
  try {
    const data = await instance.post('/api/auth/login', { username, password }).then((res) => res.data);
    return data;
  } catch (err: any) {
    const data = err.response.data;
    return { hasError: true, message: data.detail || data };
  }
}

export async function logout() {
  try {
    const data = await instance.get('/api/auth/logout').then((res) => res.data);

    return data;
  } catch {
    return null;
  }
}

export async function forgotpassword({ email }: any) {
  try {
    const data = await instance.post('/api/auth/forgotpassword', { email }).then((res) => res.data);
    return data;
  } catch (err: any) {
    const data = err.response.data;
    return { hasError: true, message: data.detail || data };
  }
}

export async function resetpassword({ password, token }: any) {
  try {
    const data = await instance.post('/api/auth/resetpassword', { password, token }).then((res) => res.data);
    return data;
  } catch (err: any) {
    const data = err.response.data;
    return { hasError: true, message: data.detail || data };
  }
}
