import filter from 'lodash/filter';
import { logout } from 'services/auth';

export const getHeaderItemsRight = ({ navigate }) => [
  {
    user: true,
    guest: true,
    sm: true,
    item: {
      title: 'Knowledge',
      link: {
        href: '',
        onClick: () => {
          navigate('/article/all');
        },
      },
    },
  },
  {
    user: true,
    guest: false,
    sm: true,
    item: {
      title: 'Leaders',
      link: {
        href: '',
        onClick: () => {
          navigate('/leader/all');
        },
      },
    },
  },
  {
    user: true,
    guest: false,
    sm: true,
    item: {
      title: 'Investment Journal',
      link: {
        href: '',
        onClick: () => {
          navigate('/journal/all');
        },
      },
    },
  },
  {
    user: true,
    guest: false,
    sm: true,
    item: {
      title: 'Character Building',
      link: {
        href: '',
        onClick: () => {
          navigate('/character-building');
        },
      },
    },
  },
  {
    user: true,
    guest: false,
    sm: true,
    md: true,
    item: {
      title: 'Dashboard',
      icon: 'activity-outline',
      link: {
        href: '',
        onClick: () => {
          navigate('/dashboard');
        },
      },
    },
  },
  {
    user: true,
    guest: false,
    sm: true,
    md: true,
    item: {
      title: 'Profile',
      icon: 'settings-outline',
      link: {
        href: '',
        onClick: () => {
          navigate('/profile');
        },
      },
    },
  },
  {
    user: true,
    guest: false,
    sm: true,
    md: true,
    item: {
      title: 'Log out',
      icon: 'log-out-outline',
      link: {
        href: '',
        onClick: async () => {
          await logout();
          window.location.href = '/';
        },
      },
    },
  },
  {
    user: false,
    guest: true,
    sm: true,
    item: {
      title: 'Log In',
      icon: 'log-in-outline',
      link: {
        href: '',
        onClick: () => {
          navigate('/auth/login');
        },
      },
    },
  },
  {
    user: false,
    guest: true,
    sm: true,
    item: {
      title: 'Sign Up',
      icon: 'person-add-outline',
      link: {
        href: '',
        onClick: () => {
          navigate('/auth/register');
        },
      },
    },
  },
];

export const filterHeaderItemsRight = (items, filters) => {
  return filter(items, filters).map((v: any) => v.item);
};
